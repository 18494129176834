//
// Custom
//

@use 'sass:map';

.scroll-margin {
	scroll-margin-top: map.get($header, height) + map.get($subheader, height) +
		map.get($page, padding-y);
}

div.select__menu {
	z-index: 1000 !important;
}

.card-body-with-table-sticky-header {
  padding-top: 0;
}

.table-sticky-header {
	th {
		position: sticky;
		top: 0;
        z-index: $zindex-sticky;
	}

    > thead > tr > th {
        background-color: $gray-100;
    }
}
